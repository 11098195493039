<template>
  <div>
    <div class="row" v-if="codigoRol == 'ADM'">
      <div class="col-md-12">
        <b-form>
          <div class="card card-custom">
            <div class="card-header flex-wrap border-0 pt-6 pb-0">
              <div class="card-title">
                <h3 class="card-label">
                  <i class="fas fa-filter"></i> Filtros
                </h3>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-3">
                  <label class="font-weight-bold">Código de Local</label>
                  <b-form-input
                    id="input-1"
                    v-model="filter.codigo"
                    required
                    placeholder="Código"
                  ></b-form-input>
                </div>
                <div class="col-md-3">
                  <label class="font-weight-bold">Local de votación</label>
                  <b-form-input
                    id="input-2"
                    v-model="filter.descripcion"
                    required
                    placeholder="Nombre o dirección"
                  ></b-form-input>
                </div>
                <div class="col-md-3">
                  <label class="font-weight-bold">Región</label>
                  <select class="form-control" v-model="filter.regionId">
                    <option :value="null">- Seleccionar -</option>
                    <option
                      v-for="region in regiones"
                      :value="region.regionId"
                      :key="region.regionId"
                      >{{ region.nombre }}</option
                    >
                  </select>
                </div>
                <div class="col-md-3">
                  <label class="font-weight-bold"> Sub región</label>
                  <select class="form-control" v-model="filter.subRegionId">
                    <option :value="null">- Seleccionar -</option>
                    <option
                      v-for="subRegion in subRegiones"
                      :value="subRegion.subRegionId"
                      :key="subRegion.subRegionId"
                      >{{ subRegion.nombre }}</option
                    >
                  </select>
                </div>
              </div>
            </div>
            <div class="card-footer padding-t-b">
              <div class="col-md-12">
                <a
                  href="#"
                  class="btn btn-primary font-weight-bold mr-2 float-right"
                  @click.prevent="fetchLocalesVotacionUsuario()"
                  ><i class="fa fa-search"></i> Buscar</a
                >
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
    <br />
    <div class="row" v-if="estaCargandoDatos">
      <div class="col-md-12">
        <div class="alert alert-light">
          <i class="fa fa-spinner fa-spin"></i> Cargando datos...
        </div>
      </div>
    </div>
    <div class="row" v-if="!estaCargandoDatos && locales.length == 0">
      <div class="col-md-12">
        <div
          role="alert"
          class="alert alert-custom alert-light-warning fade show"
        >
          <div class="alert-icon">
            <i class="fa fa-exclamation-triangle"></i>
          </div>
          <div class="alert-text">
            No hay locales asociados a tu usuario. Quizá estés buscando la lista
            de turnos asociados. Ir a
            <router-link to="/location/list/localVotacionUsuarioTurno"
              >Mis Turnos</router-link
            >.
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="!estaCargandoDatos && locales.length != 0">
      <div class="col-md-12">
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                <i class="fa fa-fw fa-clipboard-check"></i> Mis Locales
              </h3>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table
                class="table table-head-custom table-vertical-center table-head-bg table-hover"
              >
                <thead>
                  <tr>
                    <th>
                      Local / Dirección
                    </th>
                    <th>
                      Sub Región / Región
                    </th>
                    <th class="text-center">
                      T1
                    </th>
                    <th class="text-center">
                      T2
                    </th>
                    <th class="text-center">
                      T3
                    </th>
                    <th class="text-center">
                      T4
                    </th>
                    <th class="text-center">
                      %
                    </th>
                    <th>
                      <label></label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="local in locales" :key="local.localVotacionId">
                    <td>
                      <span
                        @click.prevent="
                          doFichaSeguimientoElectoral(local.localVotacionId)
                        "
                        >{{ local.nombre }}</span
                      ><br />
                      <small
                        class="text-muted"
                        @click.prevent="
                          doFichaSeguimientoElectoral(local.localVotacionId)
                        "
                        >{{ local.direccion }}</small
                      >
                    </td>
                    <td>
                      {{ local.subRegionNombre }}<br />
                      <small class="text-muted">{{ local.regionNombre }}</small>
                    </td>
                    <td class="text-right border-left">
                      {{ local.porcentajeAvanceTurno1.toFixed(0) }}
                      <i
                        class="fa "
                        :class="[
                          local.tieneRegistroFinalizadoTurno1
                            ? 'fa-lock'
                            : 'fa-unlock-alt text-light',
                        ]"
                        v-b-tooltip.top
                        :title="
                          local.tieneRegistroFinalizadoFicha ? 'Finalizado' : ''
                        "
                      ></i>
                    </td>
                    <td class="text-right">
                      {{ local.porcentajeAvanceTurno2.toFixed(0) }}
                      <i
                        class="fa "
                        :class="[
                          local.tieneRegistroFinalizadoTurno2
                            ? 'fa-lock'
                            : 'fa-unlock-alt text-light',
                        ]"
                        v-b-tooltip.top
                        :title="
                          local.tieneRegistroFinalizadoFicha ? 'Finalizado' : ''
                        "
                      ></i>
                    </td>
                    <td class="text-right">
                      {{ local.porcentajeAvanceTurno3.toFixed(0) }}
                      <i
                        class="fa "
                        :class="[
                          local.tieneRegistroFinalizadoTurno3
                            ? 'fa-lock'
                            : 'fa-unlock-alt text-light',
                        ]"
                        v-b-tooltip.top
                        :title="
                          local.tieneRegistroFinalizadoFicha ? 'Finalizado' : ''
                        "
                      ></i>
                    </td>
                    <td class="text-right">
                      {{ local.porcentajeAvanceTurno4.toFixed(0) }}
                      <i
                        class="fa "
                        :class="[
                          local.tieneRegistroFinalizadoTurno4
                            ? 'fa-lock'
                            : 'fa-unlock-alt text-light',
                        ]"
                        v-b-tooltip.top
                        :title="
                          local.tieneRegistroFinalizadoFicha ? 'Finalizado' : ''
                        "
                      ></i>
                    </td>
                    <!-- <td class="text-right">
                      {{ local.porcentajeAvanceTurno5.toFixed(0) }}
                      <i
                        class="fa "
                        :class="[
                          local.tieneRegistroFinalizadoTurno5
                            ? 'fa-lock'
                            : 'fa-unlock-alt text-light',
                        ]"
                        v-b-tooltip.top
                        :title="
                          local.tieneRegistroFinalizadoFicha ? 'Finalizado' : ''
                        "
                      ></i>
                    </td> -->
                    <td class="text-right border-left border-right">
                      {{ local.porcentajeAvanceFicha.toFixed(0) }}
                      <i
                        class="fa"
                        :class="[
                          local.tieneRegistroFinalizadoFicha
                            ? 'fa-lock'
                            : 'fa-unlock-alt text-light',
                        ]"
                        v-b-tooltip.top
                        :title="
                          local.tieneRegistroFinalizadoFicha ? 'Finalizado' : ''
                        "
                      ></i>
                    </td>
                    <td>
                      <b-button
                        href="#"
                        class="btn btn-primary font-weight-bold mr-2 alignment-right"
                        size="sm"
                        @click.prevent="
                          doFichaSeguimientoElectoralNewTab(
                            local.localVotacionId
                          )
                        "
                        ><i class="la la-clipboard-list"></i> Ficha</b-button
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Table from "../pages/vue-bootstrap/Table.vue";
import Swal from "sweetalert2";

/*intervalos de tiempo de Inactividad*/
import {
  INACTIVE_USER_TIME_THRESHOLD,
  USER_ACTIVITY_THROTTLER_TIME,
} from "@/helpers/constants.js";

export default {
  name: "local-votacion-usuario-list",
  components: {},
  props: {},
  data: () => ({
    locales: [],
    codigoRol: "",
    pagination: {
      pageSize: 10,
    },
    filter: {
      regionId: null,
      subRegionId: null,
      codigo: "",
      descripcion: "",
    },
    regiones: [],
    subRegiones: [],
    estaCargandoDatos: true,
    /*variables de inactividad*/
    isInactive: false,
    userActivityThrottlerTimeout: null,
    userActivityTimeout: null,
  }),
  computed: {},
  methods: {
    doFichaSeguimientoElectoral: function(localVotacionId) {
      this.$router.push({
        name: "sheet-local-edit",
        params: {
          procesoElectoralId: this.$session.get("ProcesoElectoralId"),
          localVotacionId: localVotacionId,
        },
      });
    },
    doFichaSeguimientoElectoralNewTab: function(localVotacionId) {
      let routeData = this.$router.resolve({
        name: "sheet-local-edit",
        params: {
          procesoElectoralId: this.$session.get("ProcesoElectoralId"),
          localVotacionId: localVotacionId,
        },
      });

      window.open(routeData.href, "_blank");
    },
    handleAddEditLocalVotacionSaved: function(obj) {
      //alert(JSON.stringify(obj));
      this.fetchLocalesVotacion();
    },
    fetchLocalesVotacionUsuario: function() {
      var model = {
        ...this.pagination,
        ...this.filter,
        ProcesoElectoralId: this.$session.get("ProcesoElectoralId"),
        UsuarioId: this.$session.get("UsuarioId"),
      };

      this.$http
        .post(this.apiRoute("Location", "ListLocalesVotacionUsuario"), model)
        .then(function(response) {
          this.locales = response.body.data.items;
          this.pagination = response.body.data.pagination;
          this.estaCargandoDatos = false;
        });
    },
    fetchRegiones: function() {
      var modelRegion = {};
      this.$http
        .post(this.apiRoute("Region", "ListRegiones"), modelRegion)
        .then(function(response) {
          this.regiones = response.body.data.items;
        });
    },
    fetchSubRegiones: function(regionId) {
      this.$http
        .get(this.apiRoute("Region", "ListSubRegionesPorRegion"), {
          params: {
            RegionId: regionId,
          },
        })
        .then(function(response) {
          this.subRegiones = response.body.data.items;
        });
    },
    /*Metodos para verificar la inactividad del usuario*/
    activateActivityTracker: function() {
      window.addEventListener("mousedown", this.userActivityThrottler);
      window.addEventListener("mousemove", this.userActivityThrottler);
      window.addEventListener("scroll", this.userActivityThrottler);
      window.addEventListener("keydown", this.userActivityThrottler);
      window.addEventListener("resize", this.userActivityThrottler);
      window.addEventListener("touchstart", this.userActivityThrottler); //para dispositivos moviles
    },
    deactivateActivityTracker: function() {
      window.removeEventListener("mousedown", this.userActivityThrottler);
      window.removeEventListener("mousemove", this.userActivityThrottler);
      window.removeEventListener("scroll", this.userActivityThrottler);
      window.removeEventListener("keydown", this.userActivityThrottler);
      window.removeEventListener("resize", this.userActivityThrottler);
      window.removeEventListener("touchstart", this.userActivityThrottler); //para dispositivos moviles
    },
    resetUserActivityTimeout: function() {
      clearTimeout(this.userActivityTimeout);

      this.userActivityTimeout = setTimeout(() => {
        this.userActivityThrottler();
        this.inactiveUserAction();
      }, INACTIVE_USER_TIME_THRESHOLD); /*=> INACTIVE_USER_TIME_THRESHOLD */
    },
    userActivityThrottler: function() {
      if (this.isInactive) {
        this.isInactive = false;
      }

      if (!this.userActivityThrottlerTimeout) {
        this.userActivityThrottlerTimeout = setTimeout(() => {
          this.resetUserActivityTimeout();
          clearTimeout(this.userActivityThrottlerTimeout);
          this.userActivityThrottlerTimeout = null;
        }, USER_ACTIVITY_THROTTLER_TIME); /*=> USER_ACTIVITY_THROTTLER_TIME*/
      }
    },
    inactiveUserAction: function() {
      this.isInactive = true;

      Swal.fire({
        icon: "warning",
        title: "Sesión Expirada",
        text: "Su sesión ha expirado. Por favor vuelva a iniciar sesión.",
        confirmButtonText: `Iniciar Sesión`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.deactivateActivityTracker();
          clearTimeout(this.userActivityTimeout);
          clearTimeout(this.userActivityThrottlerTimeout);
          this.$router.push({ name: "login" });
        }
      });
    },
  },
  watch: {
    "filter.regionId": function(newValue, oldValue) {
      this.fetchSubRegiones(newValue);
    },
  },
  mounted: function() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Mis Locales" }]);
    this.codigoRol = this.$session.get("CodigoRol");
    this.fetchRegiones();
    this.fetchLocalesVotacionUsuario();
    this.activateActivityTracker();
  },
  destoyed: function() {},
};
</script>
